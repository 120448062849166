/**
 * Action types for the Assistant
 */
export const SELECT_SUGGESTION = 'SELECT_SUGGESTION';

// Load Prop Database Actions
export const LOAD_DATASOURCE_PROPS = 'LOAD_DATASOURCE_PROPS';

export const LOAD_DATABASE_SCHEMA_PROPS = 'LOAD_DATABASE_SCHEMA_PROPS';

export const LOAD_DATABASE_SCHEMA_TABLE_PROPS =
  'LOAD_DATABASE_SCHEMA_TABLE_PROPS';

export const CLEAR_DATABASE_SCHEMA_TABLE_PROPS =
  'CLEAR_DATABASE_SCHEMA_TABLE_PROPS';

export const LOAD_DATABASE_SCHEMA_TABLE_COLUMNS_PROPS =
  'LOAD_DATABASE_SCHEMA_TABLE_COLUMNS_PROPS';

export const CLEAR_DATABASE_SCHEMA_TABLE_COLUMNS_PROPS =
  'CLEAR_DATABASE_SCHEMA_TABLE_COLUMNS_PROPS';

export const UPDATE_DATABASE_SCHEMA_TABLE_PROPS =
  'UPDATE_DATABASE_SCHEMA_TABLE_PROPS';

export const NEW_PROMPT = 'NEW_PROMPT';
export const UPDATE_PROMPT_RESPONSE = 'UPDATE_PROMPT_RESPONSE';
